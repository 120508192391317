/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import {
  ButtonSearch,
  FilterAction,
  ItemInput,
  SelectItemLarge,
  Wrap,
  WrapperLabel,
} from "./styled";
import { Form, Pagination, Select, Table } from "antd";
import { FormInput, Notification } from "Components";
import { SearchOutlined } from "@ant-design/icons";
import * as yup from "yup";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { getDetailSeihoku, getListSeihoku } from "Apis/admin";
import { formatDate } from "Utils/convertValue";
import ModalSeihokuDetail from "./ModalSeihokuDetail";
import LoadingModal from "Pages/LoadingModal";
import { useSelector, useDispatch } from "react-redux";
import actions from "Store/Actions";
import { TableContainer } from "Layouts/Admin/styled";
import moment from 'moment';
import { textNoData } from "Utils/data-default";
import { ERROR_API_MESSAGE } from "Constants";

const SeihokuData = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [total, setTotal] = useState(50);
  const [loadding, setLoadding] = useState(false);
  const [listSeihoku, setListSeihoku] = useState([]);
  const [openDetailModal, setOpenDetaiModal] = useState(false);
  const [dataDetail, setDataDetail] = useState();
  const [loaddingPage, setLoaddingPage] = useState(false);
  const [dataSearch, setDataSearch] = useState({})
  const [resetPage, setResetPage] = useState(1)
  const { getVersion } = actions;
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState({
    version: false,
  });
  const { dataVersion } = useSelector((state) => state.admin);

  useEffect(() => {
    !dataVersion.length && dispatch(getVersion((action, res) => {}));
  }, []);

  useEffect(() => {
    async function fetchData() {
      setLoadding(true);
      let dataFilter = {};
      if (dataSearch) {
        for (let key in dataSearch) {
          if (dataSearch.hasOwnProperty(key) && dataSearch[key] !== '' && dataSearch[key] !== undefined) {
            dataFilter[key] = dataSearch[key];
          }
        }
        }
      try {
        const data = await getListSeihoku({
          page: currentPage ?? 1,
          size: pageSize,
          ...dataFilter
        });
        const result = data.data;
        setTotal(result.total);
        setListSeihoku(
          result.items.map((item, index) => ({ ...item, key: index }))
        );
        setPageSize(result.size);
        setCurrentPage(result.page);
        setLoadding(false);
      } catch (error) {
        setLoadding(false);
        Notification.error(
          ERROR_API_MESSAGE[error.message_code] || ERROR_API_MESSAGE[error.code] ||
            error.message ||
            error.errors?.[0].msg
        )
      }
    }
    fetchData();
  }, [pageSize, currentPage, resetPage]);
  const handleShowDetail = async (data) => {
    setLoaddingPage(true);
    try {
      const res = await getDetailSeihoku({
        aspiration_id: data.aspiration_id,
        branch_name: data.branchname,
        version: data.version,
      });
      const result = res.data.data;
      setDataDetail(result);
      setOpenDetaiModal(true);
      setLoaddingPage(false);
    } catch (error) {
      setLoaddingPage(false);
      Notification.error(error.errors?.[0].msg || "error");
    }
  };
  const columns = [
    {
      title: "連番",
      render: (text, record, index) => (
        <span className="id_record" onClick={() => handleShowDetail(record)}>
          {(currentPage - 1) * pageSize + index + 1}
        </span>
      ),
      key: "stt",
    },
    {
      title: "コード",
      dataIndex: "aspiration_id",
      key: "aspiration_id",
    },
    {
      title: "大学名",
      dataIndex: "university_name",
      key: "university_name",
    },
    {
      title: "学部名（正式）",
      dataIndex: "faculty_name",
      key: "faculty_name",
    },
    {
      title: "学科・専攻等名称（正式）",
      dataIndex: "department_name",
      key: "department_name",
      className:'min-150'
    },
    {
      title: "日程・方式等（正式）",
      dataIndex: "schedule_method",
      key: "schedule_method",
    },
    {
      title: "枝名",
      dataIndex: "branchname",
      key: "branchname",
    },

    {
      title: " バージョン",
      dataIndex: "version",
      key: "version",
    },
    {
      title: "インポート日付",
      dataIndex: "created_at",
      key: "created_at",
      className:'min-150',
      render: (created_at) => <span>{formatDate(created_at)}</span>,
    },
  ];

  const handlePaginationChange = (current, pageSize) => {
    setCurrentPage(current);
    setPageSize(pageSize);
  };

  const validationSchema = yup
    .object({
      university_name: yup.string(),
      aspiration_id: yup.string(),
      version: yup.number(),
      create_from: yup.string().test('check-import-to', '開始日は終了日よりの前に日付を選択してください', function(value) {
        const fromDate =  moment(value, "DD/MM/YYYY");
        const toDate = moment(this.parent.create_to, "DD/MM/YYYY");
        return  fromDate < toDate || !this.parent.create_to || !value;
      }),
      create_to: yup.string(),
    })
    .required();
    
  const form = useForm({
    resolver: yupResolver(validationSchema),
  });
  const {
    handleSubmit,
    setValue,
  } = form;
  const onSubmit = (value) => {
    setDataSearch(value)
    setCurrentPage(1)
    setResetPage((pre)=>pre+1)
  };
  const handleSelectKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleSubmit(onSubmit)();
    }
  };
  return (
    <Wrap>
      <div className="seihoku__import">
      </div>
      <FormProvider {...form}>
        <form id="information-form" autoComplete="off">
          <FilterAction>
            <SelectItemLarge>
              <Form.Item label={<WrapperLabel>バージョン</WrapperLabel>}>
                <Select
                  className="select_type"
                  options={dataVersion}
                  name="item1"
                  showSearch
                  allowClear
                  onChange={e => setValue("version", e)}
                  notFoundContent={textNoData}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      setIsOpen((prevState) => ({
                        ...prevState,
                        version: false,
                      }));
                    }
                    handleSelectKeyDown(e)
                  }}
                  open={isOpen.version}
                  onDropdownVisibleChange={(open) => {
                    setIsOpen((prevState) => ({
                      ...prevState,
                      version: open,
                    }));
                  }}
                />
              </Form.Item>
            </SelectItemLarge>
          </FilterAction>
          <FilterAction>
          <ItemInput>
              <FormInput label=" コード" placeholder="例）0110" name="aspiration_id" type="text"  onPressEnter={handleSelectKeyDown} allowClear/>
            </ItemInput>
            <ItemInput>
              <FormInput label="大学名"   placeholder="例）東京大学" name="university_name" type="text" onPressEnter={handleSelectKeyDown} allowClear/>
            </ItemInput>
            
            <ButtonSearch onClick={handleSubmit(onSubmit)}>
              <SearchOutlined />
              検索
            </ButtonSearch>
          </FilterAction>
        </form>
      </FormProvider>
      <TableContainer>
        <Table
          columns={columns}
          loading={loadding}
          dataSource={listSeihoku}
          pagination={false}
          scroll={{ y: 440, x: true }}
          locale={{
            emptyText: textNoData,
          }}
        />
        {listSeihoku && listSeihoku?.length > 0  && <Pagination
          style={{ marginTop: "16px", textAlign: "right" }}
          total={total}
          current={currentPage}
          onChange={handlePaginationChange}
          showSizeChanger={true}
          pageSize={pageSize}
          locale={{
            items_per_page: "/ ページ",
          }}
        />}
      </TableContainer>
      <ModalSeihokuDetail
        visibleModal={openDetailModal}
        onCancel={() => setOpenDetaiModal(false)}
        onOk={() => setOpenDetaiModal(false)}
        dataDetail={dataDetail}
      />
      {loaddingPage && (
        <LoadingModal cancelEvent={() => setLoaddingPage(false)} />
      )}
    </Wrap>
  );
};

export default SeihokuData;
