import ModalComponent from "Components/Modal";
import styled from "styled-components";
import { Col } from "antd";

export const ModalComponentWrapper = styled(ModalComponent)`
  width: 862px !important;

  .ant-modal-body {
    .ant-row {
      margin-top: 10px;
    }
    .footer_btn {
      margin-top: 20px;
      .ant-col {
        display: flex;
        justify-content: center;
      }
      .ant-col:first-child {
        button {
          margin-left: 200px;
        }
      }
      .ant-col:last-child {
        button {
          margin-right: 200px;
        }
      }
    }
  }
`;

export const HeaderModal = styled(Col)`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  padding-top: 16px;
  padding-bottom: 10px;
  border-bottom: 1px solid #d9d9d9;

  @media only screen and (max-width: 768px) {
    margin-bottom: 25px;
  }

  .header_modal {
    display: grid;
  }

  .info_title {
    font-weight: 300;
    font-size: 12px;
    line-height: 14px;
    margin-bottom: 10px;
  }

  .info_data {
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
  }
`;
export const ListInfomation = styled.div`
`;

export const Infomation = styled.div`
  display: flex;
  align-items: center;
`;
export const TitleInfo = styled.div`
  font-size: 16px;
  font-weight: bold;
  color: black;
  margin-right: 10px;
`
export const ValueInfo = styled.div`
  font-size: 14px;
  font-weight: 400;
  color: black;
`
export const TableContainer = styled.div`
  margin-top: 24px;
  .ant-table-wrapper {
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px,
      rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
    table {
      border-collapse: collapse;
      th {
        white-space: nowrap;
        text-align: center;
        color: white;
        font-size: 16px;
        font-weight: bold;
        background: linear-gradient(to bottom, #00003A 50%, #002C68 50%) !important;
        ::before{
          position: unset !important;
        }
      }
      td,
      th {
        border: 1px solid #f0f0f0;
      }
      tr {
        border-bottom: 1px solid #f0f0f0;
        td{
          text-align: center;
        }
      }
      .ant-empty-normal{
        margin: 0;
      }
    }
    .ant-table-content {
      overflow-x: auto;
    }
    .ant-pagination {
      padding:  16px;
    }
  }
  *::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px transparent;
    border-radius: 10px;
    background-color: transparent;
  }

  *::-webkit-scrollbar {
    width: 8px;
    height: 7px;
    background-color: transparent;
  }

  *::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px transparent;
    background-color: #c4c4c4;
  }
`;
