import { Input } from "antd";
import TextArea from 'antd/lib/input/TextArea';
import styled from "styled-components";

export const Wrap = styled.div`
    .icon-question{
        margin-left: 10px;
    }
`
export const ActionMaintenance = styled.div`
    margin-bottom: 30px;
`
export const TitleAndSwitch = styled.div`
    display: flex;
`
export const TitleAction = styled.div`
    color: #000;
    font-size: 20px;
    font-weight: bold;
    display: flex;
    align-items: center;
    .tool-tip{
        display: flex;
        align-items: center;
        .ant-tooltip-inner{
            width: 400px;
            font-weight: normal;
            font-size: 14px;
            line-height: 16px;
        }
    }
`
export const SwitchAction = styled.div`
    display:  flex;
    align-items: center;
`
export const LabelSwitch = styled.div`
    margin-left: 10px;
    font-size: 16px;
    color: #000;
`
export const InputNotification = styled(TextArea)`
    width: 400px;
    margin-top: 10px;
    &.error{
       border-color: #ff7875;
    }
`
export const InputSpecialUser = styled(Input)`
    width: 400px;
    margin-top: 10px;
`

export const ButtonAction = styled.button`
    width: 80px;
    height: 40px;
    letter-spacing: 1px;
    font-style: normal;
    font-weight: 700;
    font-size: 1.3rem;
    line-height: 2rem;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    background-color: rgb(22, 119, 255);
    margin-top: 15px;
    border: none;
    :disabled{
        background-color: #878484;
    }
    .anticon-save{
        margin-right: 10px;
    }
`
export const ErrorField = styled.div`
  position: relative;
`
export const ErrorMessage = styled.div`
  position: absolute;
  top: 2px;
  font-size: 12px;
  color: #ff7875;
  width: max-content;
`