import React from 'react'
import { ActionMaintenance, ButtonAction, ErrorField, ErrorMessage, InputNotification, InputSpecialUser, LabelSwitch, SwitchAction, TitleAction, TitleAndSwitch, Wrap } from './styled'
import { Switch, Tooltip } from 'antd'
import { useState } from 'react'
import { useTranslation } from 'react-i18next';
import { maintenanceAdmin } from 'Apis/admin';
import { ERROR_API_MESSAGE, RESPONSE_STATUS } from 'Constants';
import { Notification } from 'Components';
import { QuestionCircleOutlined, SaveOutlined } from '@ant-design/icons';
import { useDispatch , useSelector } from 'react-redux';
import { useEffect } from 'react';
import actions from 'Store/Actions';
import { SHOW_NOTI, VALUE_MAINTENANCE, VALUE_SWITCH } from 'Utils/data-default';
import ModalAlert from 'Components/ModalAlert';
import { useMemo } from 'react';
import { regex } from 'Utils/regex';

const {
    getInfoMaintenanceAdmin,
    updateInfoMaintenance
  } = actions;
export default function SettingMaintenance() {
    const dispatch = useDispatch();
    const [t] = useTranslation('admin');
    const [switchMaintenance, setSwitchMaintenance] = useState(false)
    const [switchNotification, setSwitchNotification] = useState(false)
    const [inputNotification, setInputNotification] = useState('')
    const [inputID, setInputID] = useState('')
    const [showModalAlert, setShowModalAlert] = useState(false)
    const [error,setError] = useState(false)
    const {
		info_maintenance
    } = useSelector((state) => state.admin);
    useEffect(() => {
        setSwitchMaintenance(VALUE_MAINTENANCE[info_maintenance?.status_maintain] === 'no_maintencance' ? false : true)
        setSwitchNotification(SHOW_NOTI[info_maintenance?.is_show_message ] === 'not_show' ? false : true)
        setInputNotification(info_maintenance?.reason_maintenance?.length > 0 ? info_maintenance?.reason_maintenance : null)
        setInputID(info_maintenance?.list_user_id ? info_maintenance?.list_user_id : '')
    }, [info_maintenance])
    
	useEffect(() => {
		dispatch(getInfoMaintenanceAdmin());
	}, [dispatch]);
    const handleMaintenance = (e) => {
        setSwitchMaintenance(e)
    }
    const handleNotification = (e) => {
        if (!e && error) {
            setError(false)
        }
        setSwitchNotification(e)
    }
    const handleMaintenanceAdmin = async () => {
        try {
            const res = await maintenanceAdmin(
                {
                    "status": switchMaintenance ? 1 : 0 ,
                    "is_show_message": switchNotification ? 1 : 0,
                    "reason_maintenance": inputNotification,
                    "list_user_id": inputID,
                }
            )
            if (res.data.status === RESPONSE_STATUS.SUCCESS) {
                Notification.success(t('message.update_maintenance_success'));
                dispatch(updateInfoMaintenance({
                    "status_maintain": switchMaintenance ? 1 : 0 ,
                    "is_show_message": switchNotification ? 1 : 0,
                    "reason_maintenance": inputNotification,
                    "list_user_id" : inputID
                }));

              }
        } catch (error) {
            Notification.error(
                ERROR_API_MESSAGE[error.message_code] || ERROR_API_MESSAGE[error.code] ||
                  error.message ||
                  error.errors?.[0].msg
              )
        }
        setShowModalAlert(false)
    }
    const validateListID = useMemo(() => {
        let listID = inputID.split(',')
        return listID.every((id) => {
            return id.length === 8
        })
    }, [inputID])
    const disableButton = useMemo(() => {
        if (inputID.length === 0 && (VALUE_SWITCH[switchMaintenance] !== info_maintenance?.status_maintain 
            || VALUE_SWITCH[switchNotification] !== info_maintenance?.is_show_message
            || inputNotification !== info_maintenance?.reason_maintenance
            || inputID !== info_maintenance?.list_user_id)
        ) {
            return false
        } 
        else if (inputID.length > 0 && validateListID && (VALUE_SWITCH[switchMaintenance] !== info_maintenance?.status_maintain 
            || VALUE_SWITCH[switchNotification] !== info_maintenance?.is_show_message
            || inputNotification !== info_maintenance?.reason_maintenance
            || inputID !== info_maintenance?.list_user_id)) {
            return false
        }
        else {
            return true
        }
    },[info_maintenance?.list_user_id, info_maintenance?.is_show_message, info_maintenance?.reason_maintenance, info_maintenance?.status_maintain, inputID, inputNotification, switchMaintenance, switchNotification, validateListID])
    
    const handleKeyDown = (e) => {
        const keyCode = e.keyCode || e.which;
        if (!regex.numberAndComma.test(e.key) && keyCode !== 8 && keyCode !== 46 && e.key !== ',') {
          e.preventDefault();
        }
    };
    const handleChangeNoti = (e) => {
        const enteredText = e.target.value;
        const lineBreaks = enteredText.split('\n').length;
        if (enteredText.length > 0 && error) {
            setError(false)
        }
        if (lineBreaks <= 4) {
            setInputNotification(enteredText)
        }
    }
    const handleModalAlert = () => {
        if (switchNotification && !inputNotification?.length) {
            setError(true)
        }
        else {
            setShowModalAlert(true)
        }
    }
    const handleInputTeacherID = (e) => {
        const text = e.target.value;
        setInputID(text.replace(regex.negationNumber, ''));
      }
    return (
      <>
        <Wrap>
            <ActionMaintenance>
                <TitleAndSwitch>
                    <TitleAction>
                    {t('maintenance.title_maintenance')} ：
                    </TitleAction>
                    <SwitchAction>
                        <Switch checked={switchMaintenance}  onChange={(e) => handleMaintenance(e)} />
                        <LabelSwitch>{ switchMaintenance ? t('maintenance.switch_on') : t('maintenance.switch_off')} </LabelSwitch>  
                    </SwitchAction>
                </TitleAndSwitch>
            </ActionMaintenance>
            <ActionMaintenance>
                <TitleAndSwitch>
                    <TitleAction>
                    {t('maintenance.maintenance_notification')} ：
                    </TitleAction>
                    <SwitchAction>
                        <Switch checked={switchNotification} onChange={(e) => handleNotification(e)} />
                        <LabelSwitch>{ switchNotification ? t('maintenance.switch_on') : t('maintenance.switch_off')} </LabelSwitch>  
                    </SwitchAction>
                </TitleAndSwitch>
                <InputNotification className={`${error ? 'error' : ''}`} maxLength={128} autoSize={{ minRows: 4, maxRows: 4 }} value={inputNotification} onChange={ (e) => handleChangeNoti(e)} />
                {error &&
                    <ErrorField>
                            <ErrorMessage>{t('maintenance.message_error_input_noti')}</ErrorMessage>
                    </ErrorField>
                }
            </ActionMaintenance>
            <ActionMaintenance>
                <TitleAction>
                    {t('maintenance.special_user')} 
                        <Tooltip className='tool-tip'
                            getPopupContainer={(triggerNode) => triggerNode}
                            title={
                                <div className='infomation-tooltip'>
                                    <div className='infomation'>{t('maintenance.special_user_tooltip_1')}</div>
                                    <div className='infomation'>{t('maintenance.special_user_tooltip_2')}</div>
                                    <div className='infomation'>{t('maintenance.special_user_tooltip_3')}</div>
                                    <div className='infomation'>{t('maintenance.special_user_tooltip_4')}</div>
                                    <div className='infomation'>{t('maintenance.special_user_tooltip_5')}</div>
                                    <div className='infomation'>{t('maintenance.special_user_tooltip_6')}</div>
                                    <div className='infomation'>{t('maintenance.special_user_tooltip_7')}</div>
                                    <div className='infomation'>{t('maintenance.special_user_tooltip_8')}</div>
                                    <div className='infomation'>{t('maintenance.special_user_tooltip_9')}</div>
                                </div>
                            }
                    >
                        <QuestionCircleOutlined className="icon-question"/>
                    </Tooltip>
                    
                </TitleAction>
                <InputSpecialUser  onKeyDown={handleKeyDown} value={inputID}  onChange={ (e) => handleInputTeacherID(e)}  ></InputSpecialUser>
                <ButtonAction
                    disabled ={disableButton}        
                    onClick={handleModalAlert}>
                    <SaveOutlined />
                    {t('maintenance.button_ok')}
                </ButtonAction>
            </ActionMaintenance>
        </Wrap>
        <ModalAlert
            visibleModal={showModalAlert}
            onOk={handleMaintenanceAdmin}
            onCancel={() => setShowModalAlert(false)}
            Title={t('maintenance.arlet_update_maintenance')}
            titleOk={t("maintenance.button_ok")}
            titleCancel={t("maintenance.button_cancel")}
        />
      </>
  )
}
