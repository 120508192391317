import React from "react";
import {
  Infomation,
  ListInfomation,
  ModalComponentWrapper,
  TitleInfo,
  ValueInfo,
  TableContainer
} from "./styled";
import { Table } from "antd";

const ModalSeihokuDetail = ({
  onOk,
  onCancel,
  visibleModal,
  dataDetail
}) => {
  const columns = [
    {
      title: "共英語配点",
      dataIndex: "英語",
      key: "英語",
      render: (value)=>value ?? ''
    },
    {
      title: "共数学配点",
      dataIndex: "数学",
      key: "数学",
      render: (value)=>value ?? ''
    },
    {
      title: "共国語配点",
      dataIndex: "国語",
      key: "国語",
      render: (value)=>value ?? ''
    },
    {
      title: "共理科配点",
      dataIndex: "理科",
      key: "理科",
      render: (value)=>value ?? ''
    },
    {
      title: "共地公選択",
      dataIndex: "地公",
      key: "地公",
      render: (value)=>value ?? ''
    },
    {
      title: "共情報配点",
      dataIndex: "情報",
      key: "情報",
      render: (value)=>value ?? ''
    },
  ];
  const columns2 = [
    {
      title: "個英語配点",
      dataIndex: "英語",
      key: "英語",
      render: (value)=>value ?? ''
    },
    {
      title: "個数学配点",
      dataIndex: "数学",
      key: "数学",
      render: (value)=>value ?? ''
    },
    {
      title: "個国語配点",
      dataIndex: "国語",
      key: "国語",
      render: (value)=>value ?? ''
    },
    {
      title: "個理科配点",
      dataIndex: "理科",
      key: "理科",
      render: (value)=>value ?? ''
    },
    {
      title: "個地公配点",
      dataIndex: "地公",
      key: "地公",
      render: (value)=>value ?? ''
    },
    {
      title: "個情報配点",
      dataIndex: "情報",
      key: "情報",
      render: (value)=>value ?? ''
    },
    {
      title: "個その他配点",
      dataIndex: "その他",
      key: "その他",
      render: (value) => value ?? ''
    },
  ];
  return (
    <ModalComponentWrapper
      open={visibleModal}
      onOk={onOk}
      onCancel={onCancel}
      centered
    >
      <ListInfomation>
        <Infomation>
          <TitleInfo>コード:</TitleInfo><ValueInfo>{dataDetail?.aspiration_id || ''}</ValueInfo>
        </Infomation>
        <Infomation>
          <TitleInfo>大学名:</TitleInfo><ValueInfo>{dataDetail?.university_name || ''}</ValueInfo>
        </Infomation>
        <Infomation>
          <TitleInfo>学部名（正式）:</TitleInfo><ValueInfo>{dataDetail?.faculty_name || ''}</ValueInfo>
        </Infomation>
        <Infomation>
          <TitleInfo>学科・専攻等名称（正式）:</TitleInfo><ValueInfo>{dataDetail?.department_name || ''}</ValueInfo>
        </Infomation>
        <Infomation>
          <TitleInfo>日程・方式等（正式）:</TitleInfo><ValueInfo>{dataDetail?.schedule_method || ''}</ValueInfo>
        </Infomation>
        <Infomation>
          <TitleInfo>枝名:</TitleInfo><ValueInfo>{dataDetail?.branch_name || ''}</ValueInfo>
        </Infomation>
      </ListInfomation>
      <TableContainer>
        <Table
          columns={columns}
          dataSource={[dataDetail?.max_score?.common]}
          pagination={false}
        />
      </TableContainer>
      <TableContainer>
        <Table
          columns={columns2}
          dataSource={[dataDetail?.max_score?.individual]}
          pagination={false}
        />
      </TableContainer>
    </ModalComponentWrapper>
  );
};

export default ModalSeihokuDetail;
